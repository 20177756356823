import React, { useEffect, useState } from 'react';
import './GoogleMapsWithKMLLayers.css';

const GoogleMapsWithKMLLayers = ({ view, location, mapOptions }) => {
  const [map, setMap] = useState(null);
  const [layers, setLayers] = useState({});
  const [kmlFiles, setKmlFiles] = useState([]);

  useEffect(() => {
    const fetchKmlData = async () => {
      try {
        //console.log(view);
        //console.log(`get-layers.php?view=${view}&location=${location}`);
        const response = await fetch(`get-layers.php?view=${encodeURIComponent(view)}&location=${encodeURIComponent(location)}`);
        const data = await response.json();
        setKmlFiles(data);
      } catch (error) {
        console.error('Error fetching KML data:', error);
      }
    };

    fetchKmlData();
  }, [view]);

  useEffect(() => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: mapOptions.latitude, lng: mapOptions.longitude },
      zoom: mapOptions.zoom,
      streetViewControl: false, // Disable Street View
      mapTypeControl: true, // Keep map type control (optional)
      mapTypeId: 'satellite', // Start with Satellite view
    });

    setMap(mapInstance);
  }, [mapOptions]);

  useEffect(() => {
    if (!map || kmlFiles.length === 0) return;

    const loadedLayers = {};

    kmlFiles.forEach((kml) => {
      const kmlLayer = new window.google.maps.KmlLayer({
        url: kml.url,
        map: map,
        preserveViewport: true,
      });

      loadedLayers[kml.name] = kmlLayer;
    });

    setLayers(loadedLayers);

    return () => {
      Object.values(loadedLayers).forEach((layer) => layer.setMap(null));
    };
  }, [map, kmlFiles]);

  const toggleLayer = (layerName) => {
    const selectedLayer = layers[layerName];
    if (selectedLayer) {
      selectedLayer.setMap(selectedLayer.getMap() ? null : map);
    }
  };

  return (
    <div>
      <div id="map"></div> {/* This will now expand to full screen due to the updated CSS */}

      {/* Overlay toggle switches on the left */}
      <div className="map-overlay">
        <h3>Toggle Layers</h3>
        {kmlFiles.map((kml) => (
          <div key={kml.name}>
            <label>
              <input
                type="checkbox"
                defaultChecked
                onChange={() => toggleLayer(kml.name)}
              />
              {kml.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoogleMapsWithKMLLayers;
