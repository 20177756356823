import React, { useEffect, useState } from 'react';
import GoogleMapsWithKMLLayers from './components/GoogleMapsWithKMLLayers';
import './App.css';

function App() {
  const [view, setView] = useState('all');
  const [location, setLocation] = useState('Sahaja');
  const [mapOptions, setMapOptions] = useState({
    latitude: 37.642343827004545, // Default latitude
    longitude: -8.38441941008251, // Default longitude
    zoom: 16,           // Default zoom level
  });


  useEffect(() => {
    // Extract URL parameters
    const params = new URLSearchParams(window.location.search);
    const viewParam = params.get('view') || view;
    const locationParam = params.get('location') || location; // Get 'location' parameter
    const latitude = parseFloat(params.get('latitude')) || mapOptions.latitude;
    const longitude = parseFloat(params.get('longitude')) || mapOptions.longitude;
    const zoom = parseInt(params.get('zoom')) || mapOptions.zoom;
    setView(viewParam);
    setLocation(locationParam); // Set location
    setMapOptions({ latitude, longitude, zoom });
  }, []); // Runs once on component mount

  return (
    <div className="App">
      {view ? (
        <GoogleMapsWithKMLLayers view={view} location={location} mapOptions={mapOptions} />
      ) : (
        <p>Loading map...</p>
      )}
    </div>
  );
}

export default App;
